<template>
  <div>
    <van-cell-group>
      <van-field
        :value="user.userInfor.name"
        label="用户名"
        disabled
      />
      <van-field
        :value="user.userInfor.companyId"
        label="商户名称"
        disabled
      />
      <van-field
        :value="user.userInfor.balance"
        label="余额"
        disabled
      />
    </van-cell-group>
    <div class="fontBox">
      <van-row>
        <van-col span="12" class="balance">
          <span class="font">余额</span>
          <span class="content">{{user.userInfor.balance}}元</span>
        </van-col>
        <van-col span="12" class="balance">
          <!-- <router-link tag="a" to="./pay">立即充值</router-link> -->
        </van-col>
      </van-row>
    </div>

    <van-cell-group>
      <van-field
        :value="user.userInfor.balance >= 0 ? '正常' : '已欠费'"
        label="用户用能状态"
        disabled
      />
      <van-field
        :value="user.userInfor.avgFee"
        label="本月消费金额"
        disabled
      />
      <van-field
        :value="user.userInfor.remainingDays"
        label="预计使用天数"
        disabled
      />
    </van-cell-group>
    <div class="fontBox">
      <van-row>
        <van-col span="12" class="balance">
          <span class="font"></span>
          <span class="content"></span>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import api from '@/api/index'
  const { getWxConfig } = api
  import { mapState, mapActions, mapGetters } from 'vuex'
  export default {
    data() {
      return {
      }
    },
    computed: {
      ...mapState(['user'])
    },
    created() {

    },
    methods: {}
  }
</script>

<style scoped lang="scss">
  .van-field .van-cell__title{
    max-width: 120px;
    width:120px;
  }
  .fontBox{
    margin: 20px auto;
    padding: 0 25px;
    .balance{
      span{
        display: inline-block;
        width: 100%;
      }
      .font{
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        color: #999;
      }
      .content{
        height: 70px;
        line-height: 70px;
        font-size: 32px;
      }
      a{
        display: inline-block;
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        text-align: center;
        border-radius: 10px;
        width: 180px;
        background-color: #4fb4e9;
        color: #FFFFFF;
        margin: 10px auto;
      }
    }
  }
</style>
